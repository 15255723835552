<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-lesson-id="lesson.id"
  >
    <div
      class="page-header"
      :data-lesson-id="lesson.id"
    >
      <router-link to="/pmp/lessons" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">Lesson {{ lesson.id }}</span>
        <span class="page-header__title">{{ lesson.title }}</span>
      </div>
    </div>

    <div class="table-container">
      <table class="table-objectives">
        <thead>
          <tr>
            <th>#</th>
            <th>
              <span class="topic">Topic</span>
              <span> / Objective</span>
            </th>
            <th>Eco</th>
            <th></th>
          </tr>
        </thead>

        <!-- <tbody>
          <tr
            v-for="objective in objectives" :key="objective.id"
            @click="onObjectiveClick(objective)"
          >
            <td class="id">
              <span class="topic">{{ formatTopicId(objective.topic.id) }}</span>
              <span class="objective">{{ formatObjectiveId(objective.id) }}</span>
            </td>
            <td>
              <span class="topic">{{ objective.topic.name }}</span>
              <span class="objective">{{ objective.name }}</span>
            </td>
            <td>
              <ul class="eco-list">
                <li v-for="eco in objective.eco" :key="eco">{{ eco }}</li>
              </ul>
            </td>
            <td class="objective-link">
              <i class="material-icons">keyboard_arrow_right</i>
            </td>
          </tr>
        </tbody> -->

        <tbody
          v-for="objective in objectives" :key="objective.id"
          @click="onObjectiveClick(objective)">
          <tr class="row-topic">
            <td>
              <span class="topic">{{ formatTopicId(objective.topic.id) }}</span>
            </td>
            <td>
              <span class="topic">{{ objective.topic.name }}</span>
            </td>
            <td></td>
            <td class="objective-link" rowspan="2">
              <i class="material-icons">keyboard_arrow_right</i>
            </td>
          </tr>

          <tr class="row-objective">
            <td>
              <span class="objective">{{ formatObjectiveId(objective.id) }}</span>
            </td>
            <td>
              <span class="objective">{{ objective.name }}</span>
            </td>
            <td>
              <ul class="eco-list">
                <li v-for="eco in objective.eco" :key="eco">{{ eco }}</li>
              </ul>
            </td>
          </tr>
        </tbody>

      </table>
    </div>

    <a :href="lesson.quizUrl" target="_blank" class="lesson-quiz-link" :data-lesson-id="lesson.id">
      <i class="material-icons">question_answer</i>
      <span>Lesson {{ lesson.id }} - Quiz</span>
      <i class="material-icons icon-right">keyboard_arrow_right</i>
    </a>
  </div>

</template>

<script>
export default {
  name: 'PmpLesson',

  props: ['id'],

  computed: {
    lesson () {
      return this.$store.state.pmpData.lessons.find((lesson) => {
        return lesson.id === this.id
      })
    },

    objectives () {
      return this.$store.state.pmpData.objectives.filter((objective) => {
        return objective.lesson === this.lesson.id
      })
    }
  },

  methods: {
    onObjectiveClick (objective) {
      this.$router.push({ name: 'pmp-objective', params: { id: objective.id } })
    },

    formatTopicId (id) {
      return id.charAt(1)
    },

    formatObjectiveId (id) {
      return id.charAt(2)
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;

    &[data-lesson-id="1"] {
      background: $orange-light;

      .table-objectives {
        tbody {
          border-bottom: 1px solid $orange-mid;
        }

        tbody:hover {
          background: $orange-hover;
        }
      }
    }

    &[data-lesson-id="2"] {
      background: $jtask-blue-light;

      .table-objectives {
        tbody {
          border-bottom: 1px solid $jtask-blue-mid;
        }

        tbody:hover {
          background: $jtask-blue-hover;
        }
      }
    }

    &[data-lesson-id="3"] {
      background: $blue-light;

      .table-objectives {
        tbody {
          border-bottom: 1px solid $blue-mid;
        }

        tbody:hover {
          background: $blue-hover;
        }
      }
    }

    &[data-lesson-id="4"]{
      background: $green-light;

      .table-objectives {
        tbody {
          border-bottom: 1px solid $green-mid;
        }

        tbody:hover {
          background: $green-hover;
        }
      }
    }

    &[data-lesson-id="5"] {
      background: $red-light;

      .table-objectives {
        tbody {
          border-bottom: 1px solid $red-mid;
        }

        tbody:hover {
          background: $red-hover;
        }
      }
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 0;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
  }

  .table-container {
    height: calc(100% - 136px);
    overflow-y: auto;
    padding: 5px;
  }

  .table-objectives {
    width: 100%;
    padding: 20px 10px 10px 10px;
    border-collapse: collapse;

    tbody tr {
      cursor: pointer;
    }

    tr.row-topic td {
      padding-bottom: 0;
    }

    tr.row-objective td {
      padding-top: 3px;
    }

    th {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      padding: 8px 8px 15px 8px;

      &:nth-child(1) {
        width: 4%;
      }

      &:nth-child(2) {
        width: 80%;
      }

      &:nth-child(3) {
        width: 5%;
      }

      &:nth-child(4) {
        width: 3%;
      }
    }

    td {
      font-size: 14px;
      line-height: 1.4;
      vertical-align: top;
      padding: 8px;
    }

    .objective {
      display: inline-block;
      width: 100%;
      margin-bottom: 4px;
    }

    .topic {
      display: inline-block;
      color: #8D8D8D;
    }

    .eco-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .objective-link {
      vertical-align: middle;
      padding: 0;
    }

  }

  .lesson-quiz-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    padding: 15px 20px;

    i {
      color: #fff;
      margin-right: 10px;
    }

    .icon-right {
      position: absolute;
      right: -5px;
    }

  }

</style>
